import React, { useState, useRef, useEffect, useMemo } from 'react';
import Navbar from '../components/Navbar';

export const Creator = {
    Me: 0,
    Bot: 1
};

const ChatMessage = ({ text, from }) => {
    const messageTime = useMemo(() => {
        return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }, []);

    return (
        <div className={`flex ${from === Creator.Me ? 'justify-end' : 'justify-start'} mb-4`}>
            {from === Creator.Me && (
                <div className='bg-slate-400 dark:bg-slate-300 dark:bg-opacity-40 font-medium shadow-xl mt-5 ml-10 bg-clip-padding 
                backdrop-filter backdrop-blur-xl bg-opacity-20 backdrop-saturate-100 backdrop-contrast-100 p-3 rounded-tr-none rounded-tl-2xl 
                rounded-bl-2xl rounded-br-2xl flex items-end gap-4 whitespace-pre-wrap max-w-sm'>
                    <p className='text-gray-800 dark:text-slate-800'>
                        {text}
                    </p>
                    <p className='text-xs text-gray-500 dark:text-gray-600'>
                        {messageTime}
                    </p>
                </div>
            )}
            {from === Creator.Bot && (
                <div className='bg-[#5188ff] dark:bg-[#5898e5] dark:bg-opacity-40 font-medium shadow-xl mt-5 mr-10 bg-clip-padding 
                backdrop-filter backdrop-blur-xl bg-opacity-20 backdrop-saturate-100 backdrop-contrast-100 p-3 rounded-tl-none rounded-tr-2xl 
                rounded-bl-2xl rounded-br-2xl flex items-end gap-4 whitespace-pre-wrap max-w-sm'>
                    <p className='text-gray-800 dark:text-gray-300'>
                        {text}
                    </p>
                    <p className='text-xs text-gray-500 dark:text-gray-400'>
                        {messageTime}
                    </p>
                </div>
            )}
        </div>
    );
};

// export const ChatMessage = ({ text, from }) => {
//     const messageTime = useMemo(() => {
//         return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
//     }, []);

//     return (
//         <div className={`overflow-y-auto px-4 flex ${from === Creator.Me ? 'justify-end' : 'justify-start'} mb-4`}>
//             {from === Creator.Me && (
//                 <div className='bg-slate-300 dark:bg-slate-400 font-medium text-lg mt-5 ml-10 p-3 rounded-tr-none rounded-tl-2xl 
//                 rounded-bl-2xl rounded-br-2xl flex items-end whitespace-pre-wrap max-w-sm'> {/* flex items-end for right alignment */}
//                     <p className='text-slate-800 mr-auto'>
//                         {text}
//                     </p>
//                     <p className='text-xs pl-3 text-gray-500'>
//                         {messageTime}
//                     </p>
//                 </div>
//             )}
//             {from === Creator.Bot && (
//                 <div className='bg-[#63e] dark:bg-[#885ffa] font-medium text-lg mt-5 mr-10 p-3 rounded-tl-none rounded-tr-2xl 
//                 rounded-bl-2xl rounded-br-2xl flex items-end whitespace-pre-wrap max-w-sm'> {/* flex items-end for right alignment */}
//                     <p className='text-white mr-auto'>
//                         {text}
//                     </p>
//                     <p className='text-xs pl-3 text-gray-300'>
//                         {messageTime}
//                     </p>
//                 </div>
//             )}
//         </div>
//     );
// };

const ChatInput = ({ onSend, disabled }) => {
    const [input, setInput] = useState('');
    const inputRef = useRef(null);

    const sendInput = () => {
        if (input.trim() !== '') {
            onSend(input);
            setInput('');
            inputRef.current.focus();
        }
    };

    const handleKeyDown = (event) => {
        if (disabled === false) {
            if (event.keyCode === 13) {
                sendInput();
            }
        }
    };

    return (
        <div className='shadow-xl bg-slate-300 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20 backdrop-saturate-100 backdrop-contrast-100 overflow-y-auto p-2 rounded-2xl flex justify-center'>
            {/* <Dictaphone></Dictaphone> */}
            <input
                ref={inputRef}  
                value={input}
                onChange={(ev) => setInput(ev.target.value)}
                className="placeholder-gray-500 dark:placeholder-gray-400 w-full py-2 px-3 text-black dark:text-gray-300 rounded-lg focus-outline-none outline-none bg-transparent"
                type="text"
                placeholder="Type your message here..."
                // disabled={disabled}
                onKeyDown={(ev) => handleKeyDown(ev)}
                autoFocus
                autoComplete="False"
            />
            {disabled && (
                <svg className='mt-1 w-8 h-8 mr-2' width="135" height="60" viewBox="0 0 100 60">
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="0" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60;"
                            begin='0s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            begin='0s'
                            values="0;20;0;"
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="20" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.2s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.2s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="40" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.4s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.4s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="60" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.6s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.6s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="80" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.8s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.8s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                </svg>
            )}
            {!disabled && input.trim() !== '' && (
                <button
                    disabled={disabled}
                    onClick={() => sendInput()}
                    className='p-2 rounded-md text-gray-800 hover:text-black dark:text-gray-400 dark:hover:text-gray-800 bottom-1.5 right-1'
                >
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                        <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                    </svg>
                </button>
            )}
        </div>
    );
};

export default function Chat() {
    const messagesRef = useRef([]); // Use useRef for messages
    const [loading, setLoading] = useState(false);
    const listRef = useRef(null); // Ref for the chat container

    useEffect(() => {
        // Find the elements
        const chatContainer = document.querySelector('.chat-container');
        if (chatContainer) { // Check if it exists
            chatContainer.classList.add('loaded');
        }
        const inputContainer = document.querySelector('.chat-input-container');
        if (inputContainer) {
            inputContainer.classList.add('loaded');
        }

        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight; // Scroll to the bottom
        }
    }, [messagesRef, listRef]);

    const callApi = async (input) => {
        setLoading(true);

        const myMessage = {
            text: input,
            from: Creator.Me,
            key: new Date().getTime()
        };

        messagesRef.current.push(myMessage);

        try {
            const api = process.env.REACT_APP_LLM;
            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: input }),
            }).then(response => response.json());

            setLoading(false);

            if (response.text) {
                const botMessage = {
                    text: response.text,
                    from: Creator.Bot,
                    key: new Date().getTime()
                };
                messagesRef.current.push(botMessage);
            } else {
                const botMessage = {
                    text: "Apologies. There was an issue. Please try again.",
                    from: Creator.Bot,
                    key: new Date().getTime()
                };
                messagesRef.current.push(botMessage);
            }
        } catch (error) {
            const botMessage = {
                text: "Apologies. There was an issue. Please try again.",
                from: Creator.Bot,
                key: new Date().getTime()
            };
            messagesRef.current.push(botMessage);
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={`h-dvh relative dark:[background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)] bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)]`}>
            <title>Chat</title>
            <Navbar />
            <main className='relative sm:max-w-6xl sm:mx-auto top-24 sm:top-32 sm:p-5'>
                <div className='chat-container rounded-tl-[2rem] rounded-tr-[2rem] flex flex-col h-[calc(100svh-6rem)] sm:h-[calc(100svh-16rem)] shadow-xl bg-slate-300 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20 backdrop-saturate-100 backdrop-contrast-100 overflow-y-auto p-3 sm:rounded-2xl mb-10' ref={listRef}>
                    <div className='mt-10 px-4 py-16 text-lg sm:py-0 rounded-tr-3xl'>
                        {messagesRef.current.map((msg) => (
                            <ChatMessage key={msg.key} text={msg.text} from={msg.from} />
                        ))}
                        {messagesRef.current.length === 0 && (
                            <div className='bg-[#5188ff] dark:bg-[#5898e5] shadow-xl sm:rounded-bl-3xl sm:rounded-br-3xl rounded-tr-3xl absolute sm:relative inset-0 z-10 sm:mb-10 sm:mr-72 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-20 dark:bg-opacity-40 backdrop-saturate-100 backdrop-contrast-100'>
                                <p className='text-left text-gray-800 dark:text-gray-200 text-2xl sm:text-3xl font-extrabold p-10'>
                                    Here are some frequently asked questions:
                                </p>
                                <ul className="list-disc text-left text-gray-800 dark:text-gray-300 text-lg font-medium pl-16 pr-10 sm:px-14 pb-10">
                                    <li>Create a subscriber</li>
                                    <li>Show me the balance of XXX</li>
                                    <li>Purchase an offer</li>
                                    <li>I want to know about an account</li>
                                    <li>Update the service status of an account</li>
                                </ul>
                                <p className='absolute bottom-12 sm:hidden block text-[#875dfb] dark:text-[#b196fb] text-4xl font-extrabold px-10 pb-10'>Try talking with me now👇</p>
                            </div>
                        )}
                    </div>
                </div>
            </main>
            <div className='chat-input-wrapper mx-auto'>
                <div className='chat-input-container absolute bottom-0 left-0 right-0 p-5 max-w-6xl mx-auto'>
                    <ChatInput
                        onSend={(input) => callApi(input)} disabled={loading} />
                </div>
            </div>
        </div >
    );
}
