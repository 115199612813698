import React, { useState, useRef, useEffect } from 'react';
import gsap from 'gsap';
import { ChatMessage } from './ChatMessage';
import { MsgLoading } from './MsgLoading';

import { ChatInput } from './ChatInput';
import '../index.css';
import '../App.css';

// import DarkLogoSmall from "../images/DarkLogoSmall.png"

export const Creator = {
    Me: 0,
    Bot: 1
};

export default function Widget() {
    const [isWidgetOpen] = useState(false);
    const messagesRef = useRef([]);
    const listRef = useRef(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.scrollTop = listRef.current.scrollHeight; // Scroll to the bottom
        }
    }, [messagesRef.current.length]);

    useEffect(() => {
        const chatButton = document.querySelector('.widget-button');
        const chatWidget = document.getElementById('chat-widget');
        const sizeButton = document.querySelector('.size-button');
        const extraContent = document.querySelector('.extra-content');

        // Find the elements
        const chatContainer = document.querySelector('.chat-container');
        if (chatContainer) { // Check if it exists
            chatContainer.classList.add('loaded');
        }
        const inputContainer = document.querySelector('.chat-input-container');
        if (inputContainer) {
            inputContainer.classList.add('loaded');
        }

        if (chatButton && chatWidget && sizeButton) {
            let isOpen = false;
            let isEnlarged = false;

            gsap.set(chatWidget, { scale: 0, transformOrigin: 'bottom right' });

            chatButton.addEventListener('click', () => {
                //When the button is clicked, close the widget if it is open
                if (isOpen) {
                    gsap.to(chatWidget, {
                        duration: 0.5,
                        ease: 'power4.out',
                        scale: 0,
                        onComplete: () => {
                            chatWidget.classList.add('hidden');
                            chatButton.classList.remove('active');
                            isOpen = false;
                            gsap.to(chatButton, { duration: 0.5, ease: 'power4.out', rotation: 0, opacity: 1 });
                        }
                    });
                    //When the button is clicked, close the extra content if it is open
                    if (isEnlarged) {
                        gsap.to('.extra-content', {
                            duration: 0.5,
                            ease: 'power4.out',
                            width: '0',
                            height: '0',
                            display: 'none'
                        });
                    }
                } else {
                    //When the button is clicked, open the widget
                    gsap.to(chatWidget, {
                        duration: 0.5,
                        ease: 'power4.out',
                        scale: 1,
                        scaleY: 1,
                        onStart: () => {
                            chatWidget.classList.remove('hidden');
                            chatButton.classList.add('active');
                            chatWidget.classList.add('flex');
                            chatWidget.classList.add('flex-col');
                            isOpen = true;
                        }
                    });
                    //When the button is clicked, open the extra content
                    if (isEnlarged) {
                        gsap.to('.extra-content', {
                            duration: 0.5,
                            ease: 'power4.out',
                            width: '18rem',
                            height: '47rem',
                            display: 'block'
                        });
                    }
                }
                gsap.to(chatButton, { duration: 0.5, ease: 'power4.out', rotation: 365 });
            });

            sizeButton.addEventListener('click', () => {
                isEnlarged = !isEnlarged;

                //When the enlarge button is clicked, increase the widget size and open the extra content 
                if (isEnlarged) {
                    gsap.to(chatWidget, {
                        duration: 0.5,
                        ease: 'power4.out',
                        width: '67rem',
                        height: '47rem'
                    });
                    gsap.to('.extra-content', {
                        duration: 0.5,
                        ease: 'power4.out',
                        width: '18rem',
                        height: '47rem',
                        display: 'block'
                    });
                }
                //When the enlarge button is clicked, decrease the widget size and close the extra content if the widget is already enlarged
                else {
                    gsap.to(chatWidget, {
                        duration: 0.5,
                        ease: 'power4.out',
                        width: '20rem',
                        height: '32rem'
                    });
                    gsap.to('.extra-content', {
                        duration: 0.5,
                        ease: 'power4.out',
                        width: '0',
                        height: '0',
                        display: 'none'
                    });
                }
            });

            //Close the widget and extra content if the user clicks outside of it
            document.addEventListener('click', (event) => {
                if (isOpen && !chatWidget.contains(event.target) && !extraContent.contains(event.target)) {
                    gsap.to(chatWidget, {
                        duration: 0.5, ease: 'power4.out', scale: 0,
                        onComplete: () => {
                            chatWidget.classList.add('hidden');
                            chatButton.classList.remove('active');
                            isOpen = false;
                            gsap.to(chatButton, { duration: 0.5, ease: 'power4.out', rotation: 0, opacity: 1 });
                        }
                    });
                    if (isEnlarged) {
                        gsap.to('.extra-content', {
                            duration: 0.5,
                            ease: 'power4.out',
                            width: '0',
                            height: '0',
                            display: 'none'
                        });
                    }
                }
            });
        }
    }, [isWidgetOpen]);

    const callApi = async (input, notRegenerate) => {
        setLoading(true);

        const myMessage = {
            text: input,
            from: Creator.Me,
            key: new Date().getTime()
        };

        if (notRegenerate) {
            messagesRef.current.push(myMessage);
        }

        try {
            // const api = process.env.NEXT_PUBLIC_BOT_API;
            const api = process.env.REACT_APP_LLM;
            const response = await fetch(api, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ text: input }),
            }).then(response => response.json());

            setLoading(false);

            if (response.text) {
                const botMessage = {
                    text: response.text,
                    from: Creator.Bot,
                    key: new Date().getTime()
                };
                messagesRef.current.push(botMessage);
            } else {
                const botMessage = {
                    text: "Apologies. There was an issue. Please try again.",
                    from: Creator.Bot,
                    key: new Date().getTime()
                };
                messagesRef.current.push(botMessage);
            }
        } catch (error) {
            const botMessage = {
                text: "Apologies. There was an issue. Please try again.",
                from: Creator.Bot,
                key: new Date().getTime()
            };
            messagesRef.current.push(botMessage);
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    const regenerateMessage = () => {
        // Find the index of the last user message
        const lastUserMessageIndex = messagesRef.current
            .slice()
            .reverse()
            .findIndex(msg => msg.from === Creator.Me);

        if (lastUserMessageIndex !== -1) {
            // Get the text of the last user message
            const lastUserMessageText = messagesRef.current[messagesRef.current.length - lastUserMessageIndex - 1].text;

            // Find the index of the last bot message
            const lastBotMessageIndex = messagesRef.current
                .slice()
                .reverse()
                .findIndex(msg => msg.from === Creator.Bot);

            if (lastBotMessageIndex !== -1) {
                // Remove the last bot message
                messagesRef.current.splice(-lastBotMessageIndex - 1, 1);

                callApi(lastUserMessageText, false);
            }
        }
    };


    return (
        <div>
            <main>
                <div className="hidden md:block lg:block fixed bottom-5 right-5 z-10">
                    <button className="widget-button bg-purple-700 hover:bg-purple-600 dark:bg-purple-600 dark:hover:bg-purple-700
                     text-white h-14 font-bold py-2 px-4 rounded-full shadow-md focus:outline-none focus:shadow-outline">
                        <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgba(255,255,255,1)"><path
                            d="M7.29117 20.8242L2 22L3.17581 16.7088C2.42544 15.3056 2 13.7025 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 
                        22 12C22 17.5228 17.5228 22 12 22C10.2975 22 8.6944 21.5746 7.29117 20.8242ZM7 12C7 14.7614 9.23858 17 12 17C14.7614 17 
                        17 14.7614 17 12H15C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12H7Z"></path></svg>
                    </button>
                </div>
                <div className="fixed md:block lg:block bottom-5 right-5 z-10">
                    {/* Circle button */}
                </div>

                {/* Chat Widget (Initially hidden) */}
                <div className='chat-container z-10 absolute bottom-20  bg-gray-100 right-10 max-w-[60%] md:max-w-[70%] lg:max-w-[73%] max-h-[70%] md:max-h-[70%] 
                lg:max-h-[85%] shadow-md  h-[32rem] pb-2 px-0 w-80 dark:bg-gray-900 rounded-2xl shadow-3xl ml-10 hidden' id="chat-widget">
                    <div className="flex flex-col h-full w-full">
                        <div className='messages-container relative h-full overflow-y-auto' id='messages-container' ref={listRef}>
                            <div className="top-bar dark:bg-slate-900 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-30
                    dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 z-10 p-5 h-[5rem] w-full sticky top-0 ">
                                <div className='flex items-center mr-3'>
                                    <svg className="items-center bg-gray-200 dark:bg-slate-500 fill-purple-700 dark:fill-purple-300 stroke-purple-700
                             dark:stroke-purple-300 rounded-full p-2 h-10 w-10 mr-2"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" strokeWidth="1.5"
                                    >
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                    </svg>

                                    <p className='text-2xl dark:text-gray-300 mr-auto my-auto font-medium'>
                                        Bolt
                                    </p>
                                    <button className="hidden size-button lg:flex dark:text-gray-300 mx-0">
                                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                            <path d="M3 3H10.5L7.45711 6.04289L10.7071 9.29289L9.29289 10.7071L6.04289 7.45711L3 10.5V3ZM21 21H13.5L16.5429 17.9571L13.2929 14.7071L14.7071 13.2929L17.9571 16.5429L21 13.5V21Z"></path></svg>
                                    </button>
                                </div>
                            </div>
                            {/* <div className='messages-container bg-slate-400 rounded-tl-3xl rounded-tr-3xl px-5 overflow-y-auto mt-20 mb-20 items-end'
                            id='messages-container'> */}
                            {/* Map through messagesRef.current to render chat messages */}
                            {messagesRef.current.map((msg, index) => (
                                <ChatMessage
                                    key={msg.key}
                                    text={msg.text}
                                    from={msg.from}
                                    onRegenerate={regenerateMessage}
                                    isLastBotMessage={index === messagesRef.current.length - 1 && msg.from === Creator.Bot}
                                    isLoading={loading && index === messagesRef.current.length - 1 && msg.from === Creator.Bot}
                                />
                            ))}
                            {/* Render the loading indicator below the chat messages */}
                            {loading && <MsgLoading />}
                            {/* </div> */}
                            {messagesRef.current.length === 0 && (
                                <div className='text-xl text-center py-5 text-gray-300 dark:text-gray-700'>
                                    {new Date().toLocaleString([], { day: '2-digit', month: '2-digit', year: 'numeric' })}
                                </div>
                            )}
                        </div>
                        <div className='chat-input-container px-4 pt-3 pb-1'>
                            <ChatInput
                                onSend={(input) => callApi(input, true)} disabled={loading} />
                        </div>
                    </div>
                </div>

                <div className="extra-content hidden z-10 max-h-[70%] md:max-h-[70%] lg:max-h-[85%] max-w-[25%] md:max-w-[20%] absolute right-auto 
                left-7 bottom-20 bg-gray-100 dark:bg-gray-900 rounded-2xl shadow-md shadow-3xl mr-4">
                    <div className='w-full rounded-tr-2xl rounded-tl-2xl bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-30 
                    dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 z-10 h-[5rem] flex overflow-y-auto absolute top-0 left-0 right-0'>
                        <p className="text-2xl text-left ml-6 mt-6 my-auto font-medium dark:text-gray-300">
                            FAQ
                        </p>
                    </div>
                    <div className='extra-content text-wrap max-h-[95%] max-w-full hidden absolute text-gray-700 dark:text-gray-400 overflow-y-auto'>
                        <p className='text-wrap break-words mx-8 mt-24 text-xl p-4 bg-slate-300 dark:bg-slate-500 bg-clip-padding backdrop-filter 
                        backdrop-blur-xl bg-opacity-30 dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 rounded-2xl 
                        max-w-[80%]'>Create a subscriber</p>
                        <p className='text-wrap break-words mx-8 mt-8 text-xl w-auto p-4 bg-slate-300 dark:bg-slate-500 bg-clip-padding backdrop-filter 
                        backdrop-blur-xl bg-opacity-30 dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 rounded-2xl 
                        max-w-[80%]'>Show me the balance of XXX</p>
                        <p className='text-wrap break-words mx-8 mt-8 text-xl p-4 bg-slate-300 dark:bg-slate-500 bg-clip-padding backdrop-filter 
                        backdrop-blur-xl bg-opacity-30 dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 rounded-2xl
                        max-w-[80%]'>I want to know about an account</p>
                        <p className='text-wrap break-words mx-8 mt-8 text-xl p-4 bg-slate-300 dark:bg-slate-500 bg-clip-padding backdrop-filter 
                        backdrop-blur-xl bg-opacity-30 dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 rounded-2xl
                        max-w-[80%]'>Purchase an offer</p>
                        <p className='text-wrap break-words mx-8 mt-8 text-xl p-4 bg-slate-300 dark:bg-slate-500 bg-clip-padding backdrop-filter 
                        backdrop-blur-xl bg-opacity-30 dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 rounded-2xl 
                        max-w-[80%]'>I want to purchase an add on offer</p>
                        <p className='text-wrap break-words mx-8 mt-8 text-xl p-4 bg-slate-300 dark:bg-slate-500 bg-clip-padding backdrop-filter 
                        backdrop-blur-xl bg-opacity-30 dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 rounded-2xl 
                        max-w-[80%]'>Update the service status of an account</p>
                    </div>
                </div>
            </main>
        </div>
    );
}
