import React from 'react';
import ThemeSwitch from './ThemeSwitch';

import DarkLogoLarge from "../images/DarkLogoLarge.png"
import DarkLogoSmall from "../images/DarkLogoSmall.png"
import LightLogoLarge from "../images/LightLogoLarge.png"
// import LightLogoSmall from "../images/LightLogoSmall.png"
import { Link } from 'react-router-dom';

const Navbar = () => {
  return (
    <nav className='bg-white dark:bg-black p-4 absolute w-full z-10'>
      <div className="container mx-auto flex items-center justify-between text-5xl sm:text-6xl">
        <div>
          <Link to="/">
            {/* Show for light mode, larger */}
            <img
              className="hidden dark:hidden sm:inline h-14 w-28"
              src={LightLogoLarge}
              alt="LightLogoLarge"
            />

            {/* Show for dark mode, larger */}
            <img
              className="hidden sm:dark:inline h-14 w-28"
              src={DarkLogoLarge}
              alt="DarkLogoLarge"
            />

            {/* Show for light mode, small */}
            <img
              className="inline md:hidden h-10 w-8"
              src={DarkLogoSmall}
              alt="DarkLogoSmall"
            />

            {/* Show for dark mode, small */}
            {/* <Image
              className="hidden md:hidden dark:sm:inline h-10 w-8"
              src={DarkLogoSmall}
              alt="DarkLogoSmall"
            /> */}
          </Link>
        </div>
        <ThemeSwitch></ThemeSwitch>
      </div>
    </nav>
  );
};

export default Navbar;
