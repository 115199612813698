import React from 'react';
import { Link } from "react-router-dom";

import Navbar from '../components/Navbar';
import Widget from '../components/Widget';

import mobile from "../images/mobile.png";
import laptop from "../images/laptop.png";
import sitting from "../images/sitting.png";
import standing from "../images/standing.png";
import thinking from "../images/thinking.png";


export default function HomePage() {
    return (
        <div className="h-dvh relative dark:[background:radial-gradient(125%_125%_at_50%_10%,#000_40%,#63e_100%)] 
    bg-white [background:radial-gradient(125%_125%_at_50%_10%,#fff_40%,#63e_100%)] text-4xl sm:text-6xl">
            <title>Home</title>
            <Navbar />
            <main className="container flex flex-col px-4 sm:mx-auto items-center justify-center h-dvh my-auto">
                <div className=''>
                    <h1 className={`home-text  flex flex-col sm:flex-row items-center text-gray-800 dark:text-gray-300 font-semibold`}>
                        Ask me about
                        <span className="relative sm:ml-4 h-[1em] w-80 overflow-hidden">
                            <span className="text-center sm:text-left absolute h-full w-full -translate-y-full animate-slide leading-none text-[#9672fb]">
                                Balances
                            </span>
                            <span className="text-center sm:text-left absolute h-full w-full -translate-y-full animate-slide leading-none text-[#9672fb] [animation-delay:0.83s]">
                                Accounts
                            </span>
                            <span className="text-center sm:text-left absolute h-full w-full -translate-y-full animate-slide leading-none text-[#9672fb] [animation-delay:1.83s]">
                                Subscribers
                            </span>
                        </span>
                    </h1>
                </div>
                <div className='w-[11.1rem] sm:w-auto items-center'>
                    <Link to="/chat" className={`home-button font-semibold items-center flex justify-center py-2 px-4 mt-7 text-2xl text-gray-800 dark:text-gray-300 rounded-full bg-slate-300 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-40 backdrop-saturate-100 backdrop-contrast-100 transition duration-300 ease-in-out hover:bg-gray-700 hover:text-white hover:bg-clip-padding hover:backdrop-filter hover:backdrop-blur-xl hover:bg-opacity-90 hover:backdrop-saturate-100 hover:backdrop-contrast-100`}>
                        Chat now &nbsp;
                        <svg xmlns="http://www.w3.org/2000/svg" fill="#F77FBE" stroke="#F77FBE" viewBox="0 0 24 24" strokeWidth="1.5" className="w-6 h-6 mr-2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904 9 18.75l-.813-2.846a4.5 4.5 0 0 0-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 0 0 3.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 0 0 3.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 0 0-3.09 3.09ZM18.259 8.715 18 9.75l-.259-1.035a3.375 3.375 0 0 0-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 0 0 2.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 0 0 2.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 0 0-2.456 2.456ZM16.894 20.567 16.5 21.75l-.394-1.183a2.25 2.25 0 0 0-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 0 0 1.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 0 0 1.423 1.423l1.183.394-1.183.394a2.25 2.25 0 0 0-1.423 1.423Z" />
                        </svg>
                    </Link>
                </div>
                <div className='flex w-full justify-center mb-0'>
                    <img
                        className='hidden lg:flex mr-auto ml-auto h-64 w-auto mt-32 mb-0'
                        src={thinking}
                        alt='thinking'
                    />
                    <img
                        className='hidden lg:flex mr-auto ml-auto h-72 w-auto mt-24'
                        src={standing}
                        alt='standing'
                    />
                    <img
                        className='mr-auto ml-auto h-64 sm:h-72 w-auto mt-24 sm:mt-24'
                        src={mobile}
                        alt='mobile'
                    />
                    <img
                        className='mr-auto ml-auto h-48 sm:h-64 w-auto -mt-[-9.7rem] sm:mt-32'
                        src={sitting}
                        alt='sitting'
                    />
                    <img
                        className='hidden lg:flex mr-auto ml-auto h-72 w-auto mt-24'
                        src={laptop}
                        alt='laptop'
                    />
                </div>
                <Widget></Widget>
            </main>
        </div>
    );
}
