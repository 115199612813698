import { React, useMemo } from 'react';
import { Creator } from './Widget';

export const ChatMessage = ({ text, from, onRegenerate, isLastBotMessage }) => {
    const messageTime = useMemo(() => {
        return new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }, []);

    const handleRegenerate = (e) => {
        e.stopPropagation(); // Prevent event propagation
        if (onRegenerate && typeof onRegenerate === 'function') {
            onRegenerate();
        }
    };

    return (
        <div className={`overflow-y-auto px-4 flex ${from === Creator.Me ? 'justify-end' : 'justify-start'} mb-4`}>
            {from === Creator.Me && (
                <div className='bg-slate-300 dark:bg-slate-400 font-medium text-lg mt-5 ml-10 p-3 rounded-tr-none rounded-tl-2xl 
                rounded-bl-2xl rounded-br-2xl flex items-end whitespace-pre-wrap max-w-sm'> {/* flex items-end for right alignment */}
                    <p className='text-slate-800 mr-auto'>
                        {text}
                    </p>
                    <p className='text-xs pl-3 text-gray-500'>
                        {messageTime}
                    </p>
                </div>
            )}
            {from === Creator.Bot && (
                <div>
                    <div className='bg-[#63e] dark:bg-[#885ffa] font-medium text-lg mt-5 mr-10 p-3 rounded-tl-none rounded-tr-2xl 
                rounded-bl-2xl rounded-br-2xl flex items-end whitespace-pre-wrap max-w-sm'> {/* flex items-end for right alignment */}
                        <p className='text-gray-100 mr-auto'>
                            {text}
                        </p>
                        <p className='text-xs pl-3 text-gray-300'>
                            {messageTime}
                        </p>
                    </div>
                    <div className='flex mt-2 ml-2'>
                        <button
                            className='copy-button'
                            onClick={() => { navigator.clipboard.writeText(text) }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                className="w-5 h-5 text-gray-400 hover:text-gray-800 dark:hover:text-gray-200">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M15.666 3.888A2.25 2.25 0 0 0 13.5 2.25h-3c-1.03 0-1.9.693-2.166 1.638m7.332 0c.055.194.084.4.084.612v0a.75.75 0 0 1-.75.75H9a.75.75 0 0 1-.75-.75v0c0-.212.03-.418.084-.612m7.332 0c.646.049 1.288.11 1.927.184 1.1.128 1.907 1.077 1.907 2.185V19.5a2.25 2.25 0 0 1-2.25 2.25H6.75A2.25 2.25 0 0 1 4.5 19.5V6.257c0-1.108.806-2.057 1.907-2.185a48.208 48.208 0 0 1 1.927-.184" />
                            </svg>
                        </button>
                        {isLastBotMessage && (
                            <button
                                className='regenerate-button'
                                onClick={handleRegenerate}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor"
                                    className="w-5 h-5 ml-2 text-gray-400 hover:text-gray-800 dark:hover:text-gray-200">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                </svg>
                            </button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};