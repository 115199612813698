import React, { useState, useRef } from 'react';

export const ChatInput = ({ onSend, disabled }) => {
    const [input, setInput] = useState('');
    const inputRef = useRef(null);

    const sendInput = () => {
        if (input.trim() !== '') {
            onSend(input);
            setInput('');
            inputRef.current.focus();
        }
    };

    const handleKeyDown = (event) => {
        if (disabled === false) {
            if (event.keyCode === 13) {
                sendInput();
            }
        }
    };

    return (
        <div className='w-full bg-slate-300 dark:bg-slate-500 bg-clip-padding backdrop-filter backdrop-blur-xl bg-opacity-30 
        dark:bg-opacity-30 backdrop-saturate-100 backdrop-contrast-100 overflow-y-auto p-2 rounded-3xl flex justify-center'>
            <input
                ref={inputRef}
                value={input}
                onChange={(ev) => setInput(ev.target.value)}
                className="placeholder-gray-500 text-xl dark:placeholder-gray-400 w-full py-2 px-3 text-black dark:text-gray-300 
          rounded-lg focus-outline-none outline-none bg-transparent"
                type="text"
                placeholder="Message Bolt..."
                // disabled={disabled}
                onKeyDown={(ev) => handleKeyDown(ev)}
                autoFocus
                autoComplete="False" />
            {/* {disabled && (
                <svg className='mt-1 w-8 h-8 mr-2' width="135" height="60" viewBox="0 0 100 60">
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="0" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60;"
                            begin='0s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            begin='0s'
                            values="0;20;0;"
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="20" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.2s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.2s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="40" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.4s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.4s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="60" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.6s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.6s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                    <rect className='fill-[#7344F6] dark:fill-[#a382ff]' x="80" y="0" width="10" height="60" rx="6">
                        <animate attributeType="CSS"
                            attributeName="height"
                            values="60;20;60"
                            begin='0.8s'
                            dur="1s"
                            repeatCount="indefinite" />
                        <animate attributeType="CSS"
                            attributeName="y"
                            values="0;20;0"
                            begin='0.8s'
                            dur="1s"
                            repeatCount="indefinite" />
                    </rect>
                </svg>
            )} */}
            <button
                disabled={disabled}
                onClick={sendInput}
                className='p-2 rounded-md text-gray-700 hover:text-black dark:text-gray-400 dark:hover:text-gray-200 bottom-1.5 
                            right-1'>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6">
                    <path d="M3.478 2.404a.75.75 0 0 0-.926.941l2.432 7.905H13.5a.75.75 0 0 1 0 1.5H4.984l-2.432 7.905a.75.75 0 0 0 .926.94 60.519 60.519 0 0 0 18.445-8.986.75.75 0 0 0 0-1.218A60.517 60.517 0 0 0 3.478 2.404Z" />
                </svg>
            </button>
        </div>
    );
};
