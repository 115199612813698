import { React } from 'react';

export const MsgLoading = () => {
    return (
        <div className={`overflow-y-auto px-4 flex justify-start mb-4`}>
            <div className='bg-[#63e] dark:bg-[#885ffa] font-medium text-lg mt-5 mr-10 p-3 rounded-tl-none rounded-tr-2xl 
                rounded-bl-2xl rounded-br-2xl flex items-end whitespace-pre-wrap max-w-sm'>
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="#F3F4F6" stroke="#F3F4F6" strokeWidth="15" r="15" cx="40" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#F3F4F6" stroke="#F3F4F6" strokeWidth="15" r="15" cx="100" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#F3F4F6" stroke="#F3F4F6" strokeWidth="15" r="15" cx="160" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg>
            </div>
        </div>
    );
};
